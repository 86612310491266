<template lang="pug">
.om-onboarding-qualification.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(:show-progress="false")
  .container
    template(v-if="currentStep === 'detail'")
      agency-detail(
        @backClick="routeToQualification"
        @bookClick="nextStep('booking')"
        @templatesClick="finishAndGoToTemplates"
      )
    template(v-if="currentStep === 'booking'")
      h1.text-center.font-weight-bold.font-size-2 {{ $t('onboarding.agencySubpage.booking.title') }}
      .calendar-inline-widget
        hub-spot-calendar(:calendar-url="hubSpotUrl")
      .row.justify-content-center
        .col-12.justify-content-center.d-flex
          om-button.gray-600(@click="routeToPreviousPage") {{ $t('onboarding.agencySubpage.booking.backButton') }}
        .col-12.justify-content-center.d-flex
          om-button(ghost @click="finishAndGoToTemplates") {{ $t('skip') }}
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import fullName from '@/mixins/fullName';
  import { calendarLink } from '@/config/agency';
  import WizardTop from '@/components/Wizard/Top.vue';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
  import { track } from '@/services/xray';
  import navigationMixin from '../accountSetup-navigation';

  const steps = {
    DETAIL: 'detail',
    BOOKING: 'booking',
  };

  export default {
    components: {
      WizardTop,
      HubSpotCalendar: () => import('@/components/Onboarding/HubSpotCalendar.vue'),
      AgencyDetail: () => import('@/components/Onboarding/AgencyDetail.vue'),
    },
    mixins: [fullName, navigationMixin],
    data() {
      return {
        currentStep: this.$route.params.step,
      };
    },

    computed: {
      ...mapState(['account']),

      hubSpotUrl() {
        const { firstName, lastName, phoneNumber, email } = this.account.login;
        return `${calendarLink}&firstName=${firstName}&lastName=${lastName}&phone=${phoneNumber}&email=${email}`;
      },
    },

    async created() {
      await this.fetchAccount();
    },

    mounted() {
      if (!Object.values(steps).includes(this.currentStep)) {
        this.$router.push({ params: { step: steps.DETAIL } });
      }
      if (this.currentStep === 'booking') {
        window.addEventListener('message', this.onHubSpotSuccessMessage);
      }
    },

    beforeDestroy() {
      window.removeEventListener('message', this.onHubSpotSuccessMessage);
    },

    methods: {
      ...mapActions(['fetchAccount']),

      routeToPreviousPage() {
        this.$router.go(-1);
      },

      routeToQualification() {
        this.$router.push({ name: accountSetupRouteNames.QUALIFICATIONS });
      },

      nextStep(selectedStep = false) {
        let nextStep = 'agencyType';
        if (!selectedStep) {
          if (this.currentStep === 'agencyType' || this.currentStep === 'other') {
            nextStep = 'slideshowAgencies';
          }
        } else {
          nextStep = selectedStep;
        }

        this.$router.push({ params: { step: nextStep } });
      },

      async finishAndGoToTemplates() {
        track(
          this.currentStep === steps.DETAIL
            ? 'onboarding_agency-skip-detail'
            : 'onboarding_agency-skip-booking',
        );
        await this.$store.dispatch('finishOnboarding');
        this.$router.push({ name: 'templates' });
      },

      onHubSpotSuccessMessage(e) {
        if (e.origin.includes('.hubspot.com') && e.data.meetingBookSucceeded) {
          setTimeout(() => {
            this.$store.dispatch('saveOnboardingStage', null);
            track('onboarding_agency-book');
            this.$router.push({
              name: 'templates',
            });
          }, 3500);
        }
      },
    },
  };
</script>

<style lang="sass">
  .calendar-inline-widget
    min-width: 20rem
    height: 41rem
    overflow: hidden
</style>
